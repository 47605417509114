<template>
  <div>
    <tabs-account-management />
  </div>
</template>
<script>
import TabsAccountManagement from './components/TabsAccountManagement.vue'

export default {
  components: { TabsAccountManagement },

}
</script>
<style lang="scss" scoped>

</style>
