<template>
  <div class="tabs-mf bg-white tab-payment">
    <b-tabs>
      <b-tab
        :active="tab === 'member_manager'"
      >
        <template
          #title
        >
          <span class="tab-title">{{ $t('partnerView.tabAllMember') }}</span>
        </template>
        <manager-user-tab />
      </b-tab>
      <b-tab
        :active="tab === 'activated_tab'"
        @click="getMemberActivated"
      >
        <template #title>
          <span class="tab-title">{{ $t('partnerView.tabActivated') }}</span>
        </template>
        <activated-tab />
      </b-tab>
      <b-tab
        :active="tab === 'pending_tab'"
        @click="getMemberBlock"
      >
        <template #title>
          <span class="tab-title">{{ $t('partnerView.tabBlocked') }}</span>
        </template>
        <blocked-tab />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'

import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import ManagerUserTab from './ManagerUserTab.vue'
import ActivatedTab from './ActivatedTab.vue'
import BlockedTab from './BlockedTab.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BTabs,
    BTab,
    ManagerUserTab,
    ActivatedTab,
    BlockedTab,
  },

  mixins: [toastification],

  data() {
    return {
      tab: this.$route.query.tab || 'member_manager',
    }
  },

  computed: {
    ...mapGetters(['listMemberPartnerBlocked', 'listMemberPartnerActivated', 'message', 'status']),
  },

  created() {
    this.tab = this.$route.query.tab || 'member_manager'
  },

  methods: {
    ...mapActions(['getListMemberPartnerBlocked', 'getListMemberPartnerActivated']),

    async getMemberBlock() {
      // if (this.listMemberPartnerBlocked.paging.total === 0) {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...({ status: 'blocked' }),
      }
      await this.getListMemberPartnerBlocked(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
      // }
    },

    async getMemberActivated() {
      // if (this.listMemberPartnerActivated.paging.total === 0) {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...({ status: 'active' }),
      }
      await this.getListMemberPartnerActivated(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
      // }
    },
  },
}
</script>
<style lang="scss">
.tab-payment {
  .nav-tabs {
    margin-bottom: 0;
    padding: 16px 24px 0 24px;

    .nav-link{
      padding: 0 0 16px 0;
      @media (max-width: 767px) {
        padding: 0px;
      }
    }

    .nav-item{
      @media (max-width: 767px) {
        margin-right: 20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.tab-payment {
  border-radius: var(--border-radius-lg);
}
</style>
