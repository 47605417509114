<template>
  <b-modal
    ref="modal-reason-reject"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal px-1 pt-1">
      <h3 class="font-medium text-primary">
        Reason reject
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="position-relative pl-1 pr-1 reason-reject mt-1">
      <div class="d-flex">
        <p v-if="createdDate(memberData)"><span class="text-danger font-weight-600">Verify At:</span>
          <span>
            {{ createdDate(memberData) }}
          </span>
        </p>
      </div>

      <div
        class="reason-reject-message"
      >
        <div
          class="reason-reject-content"
          v-html="reasonReject(memberData)"
        />
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>

import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import { required } from '@validations'
import numberFormatMixin from '@/mixins/numberFormatMixin'

export default {
  components: {
    BModal,
    BButton,
  },
  mixins: [numberFormatMixin, toastification],

  props: {
    memberData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      // validation
      required,
    }
  },

  computed: {
    reasonReject() {
      return data => data?.contactInfo?.verification?.rejectedReason
    },

    createdDate() {
      return value => {
        const date = value?.contactInfo?.verification?.at
        return (date ? new Date(date).toLocaleString('en-GB') : '')
      }
    },
  },

  methods: {
    hideModal() {
      this.$refs['modal-reason-reject'].hide()
    },

    showModal() {
      this.$refs['modal-reason-reject'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.reason-reject-message {
  text-align: start;
  white-space: normal;
}

.reason-reject {
  max-width: max-content;
  width: 100%;
}

.reason-reject-message {
  padding: 16px;
  border-radius: var(--border-radius-base);
  background-color: #f5f5f7;
}
</style>
