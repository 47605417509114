<template>
  <b-modal
    ref="modal-remove-member-modal"
    id="modal-remove"
    class="modal-child modal-remove"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary font-20">
        Are you sure to remove this member?
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="d-flex align-items-center info-member">
      <b-img
        rounded
        class="ads-avt-img"
        :src="require('@/assets/images/common/ic-member-manager.svg')"
      />
      <div class="d-flex flex-column">
        <p class="text-primary font-weight-600 mb-0">
          {{ memberInfo.fullName }}
        </p>
        <span>{{ memberInfo.email }}</span>
      </div>
    </div>

    <div class="form-reason">
      <!-- Body -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form>
          <template>
            <!-- Reason -->
            <validation-provider
              #default="{ errors }"
              name="reason"
              rules="required"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="form-label label-reason"> Reason </label>
                </div>
                <quill-editor
                  v-model.trim="description"
                  ref="QuillEditor"
                  id="quil-content"
                  size="20"
                  :options="editorOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </template>
          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        type="submit"
        :disabled="description === ''"
        @click.prevent="removeMember"
      >
        Submit
      </b-button>
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */

import {
  BModal,
  BButton,
  BImg,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'

import { toastification } from '@core/mixins/toast'
import { required } from '@validations'
import { createNamespacedHelpers } from 'vuex'
import { TOOLBAR_EDITOR_OPTIONS } from '@/constants'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import tableReferralProgramMixin from '@/mixins/tableReferralProgramMixin.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const { mapGetters, mapActions } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    BModal,
    BButton,
    BImg,
    BForm,
    BFormGroup,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  directives: {
    Ripple,
  },

  mixins: [toastification, tableReferralProgramMixin],

  props: {
    memberInfo: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      description: '',

      currentPage: 1,
      searchTerm: '',

      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
      },

      // validation
      required,
    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
  },

  methods: {
    ...mapActions(['removeMemberPartner']),

    hideModal() {
      this.description = ''
      this.$refs['modal-remove-member-modal'].hide()
    },

    showModal() {
      this.$refs['modal-remove-member-modal'].show()
    },

    async removeMember() {
      const success = await this.$refs.refFormObserver.validate()

      if (success) {
        const params = {
          id: this.memberInfo._id,
          rejectedReason: this.description,
        }
        await this.removeMemberPartner(params)
        if (this.status) {
          this.toastSuccess('Remove member success!')
          this.fetchListMemberPartner()
          this.hideModal()
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
#modal-remove{
  .modal-body {
    padding: 24px;
  }
  }

.reason-content {
  p {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.top-modal {
  margin-bottom: 32px;
}

.info-member {
  padding-bottom: 32px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);
}

.label-reason {
  margin-bottom: 12px;
}

.target-market-member {
  .title-target-market {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
.reason-reject {
  max-width: max-content;
  width: 100%;
}

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    height: 200px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
